<template>
  <div id="video-ident-selection">
    <p class="font-weight-medium">{{ $t('onboarding.videoIdent.title') }}</p>
    <div class="d-flex ml-10">
      <div class="d-flex flex-column justify-center align-center">
        <v-img cover height="30" width="70" src="@/assets/logos/partners/IDnow.svg" />
        <span class="partner-name pb-3">IDnow</span>
      </div>
      <div class="d-flex flex-column justify-center align-center ml-10">
        <v-img cover height="70" width="70" src="@/assets/logos/partners/bvdh.svg" />
        <span class="partner-name align-self-start">Bankhaus von der Heydt</span>
      </div>
    </div>
    <div
      v-if="idNowConnection"
    >
      <!-- <v-tabs
        v-model="tab"
        background-color="element"
        centered
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#browser">
          {{ $t('onboarding.videoIdent.browser.title') }}
          <File :color="tab === 'browser' ?
            $vuetify.theme.defaults[appTheme].primary : $vuetify.theme.dark ? '#ffffff' : '#000000'"
          />
        </v-tab>

        <v-tab href="#app">
          {{ $t('onboarding.videoIdent.mobileApp.title') }}
          <File :color="tab === 'app' ?
            $vuetify.theme.defaults[appTheme].primary : $vuetify.theme.dark ? '#ffffff' : '#000000'"
          />
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
      >
        <v-tab-item
          value="browser"
        >
          <v-card
            align="center"
            color="element"
          >
            <v-card-text>
              {{ $t('onboarding.videoIdent.browser.desc') }}
            </v-card-text>
            <v-container>
              <v-btn
                color="primary"
                class="black--text"
                large
                :href="idNowRedirect"
                target="_blank"
              >
              {{ $t('onboarding.videoIdent.browser.button') }}
              </v-btn>
            </v-container>
          </v-card>
        </v-tab-item>
        <v-tab-item
          value="app"
        >
          <v-card
            align="center"
            color="element"
          >
            <v-card-text>
              {{ $t('onboarding.videoIdent.mobileApp.desc1') }}
            </v-card-text>
            <v-container>
            <v-row
              align="center"
              justify="center"
            >
              <Badge
                type="AppStore"
              />
              <Badge
                type="GooglePlay"
              />
            </v-row>
            </v-container>
            <br>
            <br>
            <v-card-text>
              {{ $t('onboarding.videoIdent.mobileApp.desc2') }}
            </v-card-text>
            <v-container>
              <v-text-field
                readonly
                success
                :value="idNowId"
                outlined
                append-icon="mdi-content-copy"
                @click:append="copyText"
                ref="textToCopy"
              />
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items> -->
      <v-card
        color="backgroundDark"
        elevation="0"
        class="mt-5 mb-3"
        width="600"
      >
        <div class="d-flex align-center">
          <div>
            <v-img height="52" width="52" :src="require('@/assets/badges/IDnow.png')" />
          </div>
          <div class="ml-3">
            <div class="grey--text ml-1">{{ $t('onboarding.videoIdent.mobileApp.desc1') }}</div>
            <div class="d-flex align-center mt-1">
              <Badge
                type="AppStore"
              />
              <Badge
                type="GooglePlay"
              />
            </div>
          </div>
        </div>
        <br>
        <p>Ident-ID</p>
        <v-text-field
          v-if="idNowId"
          id="id-now"
          align-start
          readonly
          :value="idNowId"
          outlined
          append-icon="mdi-content-copy"
          @click:append="copyText"
          ref="textToCopy"
          class="rounded-lg"
        />
        <h3 class="font-weight-regular">{{ $t('onboarding.videoIdent.mobileApp.subtitle') }}</h3>
        <div
          v-for="i in 6"
          :key="i"
          class="d-flex mt-2 grey--text list-text"
        >
          <v-icon color="grey">mdi-check-circle-outline</v-icon>
          <div v-html="$t(`onboarding.videoIdent.mobileApp.list.${i}`)" class="ml-3" />
        </div>
      </v-card>
    </div>

    <div
      v-else
    >
      <h4>{{ $t('onboarding.videoIdent.error') }}</h4>
    </div>

    <!-- UAT Trigger KYC Status Change -->
    <div v-if="testEnvironment">
      <br><br><br><br><br>
      <v-card
        align="center"
        color="element"
      >
        <v-card-subtitle>
          {{ $t('onboarding.videoIdent.triggerKycStatusChange.info') }}
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-btn
              color="primary"
              class="black--text"
              large
              @click="triggerKycStatusChange"
              :loading="loading"
            >
              {{ $t('onboarding.videoIdent.triggerKycStatusChange.button') }}
            </v-btn>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { triggerKycStatusChange as trigger } from '@/api/testing';
import lazyLoad from '@/utils/lazyLoad';
// import File from '@/assets/icons/svg/File.vue';

export default {
  name: 'VideoIdent',
  components: {
    Badge: lazyLoad('components/Shared/Badge'),
    // File,
  },
  data() {
    return {
      tab: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('user', [
      'videoIdent',
    ]),
    ...mapGetters('application', [
      'configurations',
    ]),
    idNowConnection() {
      return !this.videoIdent.status
        || this.videoIdent.status === 'IDENT CREATED'
        || this.videoIdent.status === 'IDENT SUCCESSFUL';
    },
    idNowId() {
      return this.videoIdent.result;
    },
    idNowRedirect() {
      return `${this.videoIdent.redirect}/webcam`;
    },
    testEnvironment() {
      return this.configurations?.stage === 'uat';
    },
    appTheme() {
      if (this.$vuetify.theme.dark) {
        return 'dark';
      }
      return 'light';
    },
  },
  methods: {
    copyText() {
      const textToCopy = this.$refs.textToCopy.$el.querySelector('input');
      textToCopy.select();
      document.execCommand('copy');
      this.$notify.success(this.$t('onboarding.videoIdent.mobileApp.ccNotification'));
    },
    async triggerKycStatusChange() {
      this.loading = true;
      await trigger();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
#video-ident-selection {
  .v-text-field {
    width: 200px !important;
  }
  .v-image__image {
    background-size: 100% !important;
  }
  .partner-name {
    font-size: 10px;
    max-width: 70px;
    text-align: center;
  }
  .list-text {
    font-size: 14px;
  }
}
</style>
